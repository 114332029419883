<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import {DatePicker} from "v-calendar";
import {mapActions} from "vuex";

export default {
  name: "SettingCouponWeekday",
  components: {DatePicker},
  mixins: [ShowToastMessage, Loader],
  props: ['couponWeekendDays'],
  data() {
    return {
      selectDate: '',
      currentSetting: this.couponWeekendDays,
      selectedWeekdays: [], // Local state for selected weekdays
      highlightedWeekdays: [], // Local state for highlighted attributes
      weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    };
  },
  computed: {
    isUpdateButtonEnable() {
      return this.currentSetting.value === '' || this.currentSetting.value === null;
    },
  },
  watch: {
    // Watch for changes in the initialSelectedWeekdays prop and update state
    'currentSetting.value': {
      handler(newValue) {
        const capitalizedWeekdays = newValue.map((day) => this.capitalizeFirstLetter(day));
        if (JSON.stringify(this.selectedWeekdays) !== JSON.stringify(capitalizedWeekdays)) {
          this.selectedWeekdays = capitalizedWeekdays;
          this.updateHighlightedWeekdays();
        }
      },
    },
    selectedWeekdays(newValue) {
      if (JSON.stringify(this.currentSetting.value) !== JSON.stringify(newValue)) {
        this.currentSetting.value = [...newValue];
      }
    },
  },
  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
    }),
    // Function to capitalize the first letter of a string
    capitalizeFirstLetter(day) {
      return day.charAt(0).toUpperCase() + day.slice(1).toLowerCase();
    },
    // Get the weekday from the clicked date
    selectWeekday({date}) {
      const selectedDay = this.weekdays[date.getDay()];

      if (this.selectedWeekdays.includes(selectedDay)) {
        // Remove if already selected
        this.selectedWeekdays = this.selectedWeekdays.filter((day) => day !== selectedDay);
      } else {
        // Add if not already selected
        this.selectedWeekdays = [...this.selectedWeekdays, selectedDay];
      }

      this.updateHighlightedWeekdays();
    },

    // Update highlighted attributes based on selected weekdays
    updateHighlightedWeekdays() {
      this.highlightedWeekdays = this.selectedWeekdays.map((day) => ({
        key: day,
        customData: {weekday: day}, // Custom data if needed
        content: {
          color: 'purple',
          style: {
            fontStyle: 'italic',
          },
        },
        highlight: {
          color: 'purple',
          fillMode: 'light',
        },
        // Match the weekday column
        dates: {weekdays: this.weekdays.indexOf(day) + 1},
      }));
    },

    async updateSettingsHandler() {
      await this.updateSettings();
    },

    async updateSettings() {
      let dataObj = {
        id: this.currentSetting.id,
        data: {
          type: this.currentSetting.type,
          key: this.currentSetting.key,
          value: this.currentSetting.value,
        }
      };
      await this.loader(true);
      await this.putSetting(dataObj).then(async (response) => {
        await this.loader(false);

        if (response.status === 200) {
          const toastObj = {
            message: 'Status updated.',
            type: 'success'
          };

          this.showToastMessage(toastObj);
        }
        this.showToastMessage(response);
      });
    }
  },
}
</script>

<template>
  <div>
    <div class="row box">
      <div class="col-12 col-md-6 col-lg-6">
        <div class="form-group">
          <label>Select date</label>
          <div class="d-flex align-items-center date-picker-wrapper">
            <div class="w-100" ref="picker">
              <DatePicker
                  is-expanded
                  v-model="selectDate"
                  :attributes="highlightedWeekdays"
                  @dayclick="selectWeekday"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="pb-3 col-12 col-md-6 col-lg-6">
        <h6>Selected Weekdays:</h6>
        <div class="p-1 border border-lighten-4 rounded"> {{ selectedWeekdays.join(', ') }}</div>
      </div>
    </div>

    <div class="d-flex justify-content-center align-items-center py-2">
      <button :disabled="isUpdateButtonEnable" @click="updateSettingsHandler" class="btn btn-primary px-lg-5"
              type="submit">Update
      </button>
    </div>
  </div>
</template>

<style scoped>

</style>