// core vue
import { createApp } from 'vue';
import router from '@/router';
import store from '@/store';
import App from '@/App.vue';

//code package
import axios from "axios";
import DatePicker from 'vue3-datepicker';
import VueMultiselect from 'vue-multiselect';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import VueToast from 'vue-toast-notification';
import Loading from 'vue-loading-overlay';
import tooltip from "../public/app-assets/js/scripts/tooltip";
import Editor from '@tinymce/tinymce-vue'
// package css
import 'vue-toast-notification/dist/theme-sugar.css';//VueToast  css
import 'vue-loading-overlay/dist/vue-loading.css'; // loader spinner
import '../public/app-assets/css/tooltip.css';
import 'qalendar/dist/style.css';

import Echo from "laravel-echo";
import Pusher from "pusher-js";

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: "pusher",
    key: "10ea7f9150bbe02d6953",
    cluster: "ap2",
    encrypted: true,
});

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common['Client-Secret'] = process.env.VUE_APP_CLIENT_SECRET;

require('@/store/subscriber');
//import firebaseMessaging from './firebase.js'
store.dispatch('appAuthLogin/commitAuthAttemptFromLocalStorage').then(() => {

    const vm = createApp(App);
    vm.use(VueToast);
    vm.use(Loading);
    vm.use(store);
    vm.use(router);
    vm.use(VueGoogleMaps, {
        load: {
            key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
        },
    });

    vm.component('VueMultiselect',VueMultiselect);
    vm.component('datepicker',DatePicker);
    vm.component('Editor',Editor);
    vm.directive("tooltip", tooltip);
    //vm.config.globalProperties.$messaging = firebaseMessaging
    vm.mount('#app');

    // Ensure the service worker is registered
    /*if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/firebase-messaging-sw.js')
            .then((registration) => {
                console.log('Service Worker registered with scope:', registration.scope);
            })
            .catch((err) => {
                console.log('Service worker registration failed:', err);
            });
    }*/
});
