<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class=" d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Payments/List</h4>
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-3 col-lg-3">
              <div class="form-group">
                <label>Date</label>
                <div class="d-flex align-items-center date-picker-wrapper">
                  <div class="d-flex align-items-center">
                    <i class='bx bx-calendar'></i>
                  </div>
                  <div class="w-100 pl-1 ">
                    <div class="w-100 ">
                      <DatePicker v-model="getPaymentsParams.created_at" :update-on-input="true"
                                  :masks="{input: ['DD MMMM YYYY']}"
                                  :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                        <template v-slot="{ inputValue, inputEvents }">
                          <input class="form-control" :value="inputValue" v-on="inputEvents"/>
                        </template>
                      </DatePicker>
                    </div>
                  </div>
                </div>
                <div class="text-danger"></div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-3 col-lg-3 pt-2">
              <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                      @click="getPaymentList(this.getPaymentsParams)">Search
              </button>
            </div>

          </div>
        </div>
        <div class="users-list-table">
          <div v-if="incomeList.length" class="row">
            <div class="col-md-3">
              <div class="card">
                <div class="card-body p-1">
                  <div class=" col-12 divider divider-dotted divider-info m-0">
                    <div class="text-info text-uppercase text-bold-700 divider-text">Technician wise sales</div>
                  </div>
                  <table class="table table-bordered table-sm table-info">
                    <thead>
                    <tr>
                      <th>In Charge</th>
                      <th>No Appts</th>
                      <th>Sales Made</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(panel, index) in technicianWiseAppointmentAndSales" :key="index">
                      <td>{{ panel.technicianName }}</td>
                      <td>{{ panel.appointments }}</td>
                      <td>{{ formatNumber(panel.totalSales / 100) }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card">
                <div class="card-body p-1">
                  <div class=" col-12 divider divider-dotted divider-light m-0">
                    <div class="text-light text-uppercase text-bold-700 divider-text">Panel wise sales</div>
                  </div>
                  <table class="table table-bordered table-sm table-light">
                    <thead>
                    <tr>
                      <th>Panel</th>
                      <th>No Appts</th>
                      <th>Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(panel, index) in panelWiseAppointmentAndSales" :key="index">
                      <td>{{ panel.paymentPanel }}</td>
                      <td>{{ panel.count }}</td>
                      <td>{{ formatNumber(panel.totalSales / 100) }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card">
                <div class="card-body p-1">
                  <div class=" col-12 divider divider-dotted divider-warning m-0">
                    <div class="text-warning text-uppercase text-bold-700 divider-text">Method wise sales</div>
                  </div>
                  <table class="table table-bordered table-sm table-warning">
                    <thead>
                    <tr>
                      <th>Type</th>
                      <th>Count</th>
                      <th class="text-center">Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(panel, index) in paymentTypeWiseSales" :key="index">
                      <td>{{ panel.paymentType }}</td>
                      <td>{{ panel.count }}</td>
                      <td class="text-center">
                        <span>{{ formatNumber(panel.totalSales / 100) }}</span>
                        <span v-show="panel.paymentType === 'Card'"> | {{
                            formatNumber(panel.totalSurcharge / 100)
                          }}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card">
                <div class="card-body p-1">
                  <div class=" col-12 divider divider-dotted divider-danger m-0">
                    <div class="text-danger text-uppercase text-bold-700 divider-text">Total accumulated income</div>
                  </div>
                  <table class="table table-bordered table-sm table-danger">
                    <tbody class="text-uppercase text-bold-700">
                    <tr>
                      <td>Income received today</td>
                      <td>${{
                          parseFloat(paymentTypeWiseSales.reduce((total, current) => (total + current.totalSales), 0) / 100).toFixed(2)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td>${{
                          parseFloat(paymentTypeWiseSales.reduce((total, current) => (total + current.totalSales), 0) / 100).toFixed(2)
                        }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <div v-if="incomeList.length" class="table-responsive">
                <table id="users-list-datatable" class="table table-hover table-sm">
                  <thead class="thead-dark">
                  <tr>
                    <th class="position-relative" :style="{width:'5%'}">SL</th>
                    <th class="position-relative" :style="{width:'25%'}">Name</th>
                    <th class="position-relative" :style="{width:'10%'}">Reference</th>
                    <th class="position-relative" :style="{width:'5%'}">Panel</th>
                    <th class="position-relative" :style="{width:'10%'}">Technician</th>
                    <th class="position-relative" :style="{width:'10%'}">Paid</th>
                    <th class="position-relative" :style="{width:'10%'}">Unpaid</th>
                    <th class="position-relative" :style="{width:'5%'}">Sales</th>
                    <th class="position-relative" :style="{width:'5%'}">Card</th>
                    <th class="position-relative" :style="{width:'5%'}">Cash</th>
                    <th class="position-relative" :style="{width:'5%'}">Afterpay</th>
                    <th class="position-relative" :style="{width:'5%'}">Bank Transfer</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(income, index) in this.incomeList"
                      :key="index">
                    <td> {{ index + 1 }}</td>
                    <td>
                      <div>{{ income?.paidBy?.fullName }} | <span
                          class="font-size-small font-weight-bolder badge badge-circle-light-secondary">{{
                          income?.serviceType
                        }}</span></div>
                      <div>{{ buildAddress(income?.paidBy?.address) }}</div>
                    </td>
                    <td><span class="font-size-small font-weight-bolder badge"
                              :class="income?.paymentFor === 'Appointment' ? 'badge-circle-light-info' : 'badge-circle-light-danger'">{{
                        income?.reference
                      }}</span></td>
                    <td> {{ income?.paymentPanel }}</td>
                    <td> {{ income?.technicianFullName }}</td>
                    <td> {{
                        income.paymentFor === 'Appointment' && income?.amount > 0 ? '$' + parseFloat(income?.amount / 100).toFixed(2) : null
                      }}
                    </td>
                    <td> {{
                        income.paymentFor === 'Appointment' && income?.remaining > 0 ? income?.remaining : null
                      }}
                    </td>
                    <td> {{
                        income.paymentFor === 'Order' && income?.amount > 0 ? '$' + parseFloat(income?.amount / 100).toFixed(2) : null
                      }}
                    </td>
                    <td class="text-center table-warning">
                      <div> {{
                          income.paymentType === 'Card' && income.amount > 0 ? '$' + parseFloat(income?.amount / 100).toFixed(2) : null
                        }}
                      </div>
                      <div>
                        {{
                          income.paymentType === 'Card' && income.surcharge > 0 ? '$' + parseFloat(income?.surcharge / 100).toFixed(2) : null
                        }}
                      </div>

                    </td>
                    <td class="text-center table-danger"> {{
                        income.paymentType === 'Cash' && income?.amount > 0 ? '$' + parseFloat(income?.amount / 100).toFixed(2) : null
                      }}
                    </td>
                    <td class="text-center table-primary"> {{
                        income.paymentType === 'Afterpay' && income?.amount > 0 ? '$' + parseFloat(income?.amount / 100).toFixed(2) : null
                      }}
                    </td>
                    <td></td>
                  </tr>
                  </tbody>
                  <tfoot class="thead-light">
                  <tr class="text-uppercase text-bold-700 table-danger">
                    <td colspan="5" class="text-center">Total:</td>
                    <td>${{ parseFloat(totals.appointmentTotal/100).toFixed(2)}}</td>
                    <td>${{ parseFloat(totals.remainingTotal/100).toFixed(2)}}</td>
                    <td>${{ parseFloat(totals.orderTotal/100).toFixed(2)}}</td>
                    <td>${{ parseFloat(totals.cardTotal/100).toFixed(2)}}</td>
                    <td>${{ parseFloat(totals.cashTotal/100).toFixed(2)}}</td>
                    <td>${{ parseFloat(totals.afterpayTotal/100).toFixed(2)}}</td>
                    <td>${{ parseFloat(totals.eftTotal/100).toFixed(2)}}</td>
                  </tr>
                  </tfoot>
                </table>
                <div class="from-group text-right">
                  <button type="button" class="btn btn-sm btn-primary">Generate
                    Payment
                  </button>
                </div>
              </div>
              <div v-else class="text-center text-uppercase text-danger bg-accent-3">No record found</div>

            </div>
          </div>
        </div>
        <!--        <DeleteAlertModal/>-->
        <PaymentQuickViewModal :current-payment="singlePaymentObj" :payment-type="singlePaymentType"/>
        <PaymentDetailsModal/>
      </section>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue'

import PaymentQuickViewModal
  from "@/views/backEnd/AccountAndFinance/Finance/AllPayments/includes/PaymentQuickViewModal";
import PaymentDetailsModal from "@/views/backEnd/AccountAndFinance/Finance/AllPayments/includes/PaymentDetailsModal";

import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import {
  mapActions,
  mapGetters
} from "vuex";
import {
  DatePicker
} from "v-calendar";
import AddressMixin from "@/components/backEnd/mixins/AddressMixin";

export default {
  name: "PaymentReportCreate",
  mixins: [ShowToastMessage, Loader, AddressMixin],
  components: {
    PaymentDetailsModal,
    PaymentQuickViewModal,
    DatePicker,
    AppLayout,

  },
  data() {
    return {

      customerTypeSelectedValue: '',
      statusSelectedValue: '',
      incomeList: [],
      currentGeekDetails: {
        id: "",
        name: "",
        address: "",
        type: "",
        phone: "",
        email: "",
        status: "",

      },
      getPaymentsParams: {
        reference: '',
        type: '',
        panel: '',
        order_by_id: 'DESC',
        where_has_user_search_query: '',
        created_at: '',
        with_relation: [
          'cardPayment',
          'cardPayment.paidBy.customer.address.business',
          'cashPayment',
          'cashPayment.paidBy.customer.address.business',
          'eftPayment',
          'eftPayment.paidBy.customer.address.business',
          'afterpayPayment',
          'afterpayPayment.paidBy.customer.address.business',
          'appointmentPayment',
          'appointmentPayment.appointment.technicianAppointment.technician.user',
          'orderPaymentsByReference.user',
          'orderPaymentsByReference.shippingAddress',
          'orderPaymentsByReference.appointment.technicianAppointment.technician.user',
        ],
        paginate: 1,
        pagination: '',
        page: ''
      },
      getPaymentParams: {},
      getSettingsParams: {
        type: ['default'],
        key: ['default_pagination', 'default_state', 'default_customer_type'],
      },
      singlePaymentObj: {},
      singlePaymentType: '',
      panelWiseAppointmentAndSales: [],
      technicianWiseAppointmentAndSales: [],
      paymentTypeWiseSales: [],
      totals: {
        appointmentTotal: 0,
        remainingTotal: 0,
        orderTotal: 0,
        cardTotal: 0,
        cashTotal: 0,
        afterpayTotal: 0,
        eftTotal: 0,
      },
    }
  },
  computed: {
    ...mapGetters({
      payments: 'appPayments/payments',

    }),
  },
  methods: {
    ...mapActions({
      getPayments: 'appPayments/getPayments',
      getPayment: 'appPayments/getPayment',
      getSettings: 'appSettings/getSettings',
    }),
    formatNumber(number) {
      return number.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },
    async generatePaymentTypeStatus() {
      const detailMapping = {
        Card: (payment) => ({
          paidBy: {
            userId: payment?.cardPayment?.paidBy?.id ?? null,
            fullName: payment?.cardPayment?.paidBy?.full_name ?? null,
            email: payment?.cardPayment?.paidBy?.email ?? null,
            phone: payment?.cardPayment?.paidBy?.phone ?? null,
            address: payment?.cardPayment?.paidBy?.customer?.address ?? null,
          },
          amount: payment?.cardPayment?.amount ?? 0,
          surcharge: payment?.cardPayment?.card_surcharge ?? 0,
          type: 'Card',
          cardDetails: payment.cardPayment && {
            card_type: payment.cardPayment?.card_type ?? null,
            payment_gateway: payment.cardPayment?.payment_gateway ?? null,
            payment_gateway_id: payment.cardPayment?.payment_gateway_id ?? null,
            status: payment.cardPayment?.status ?? null,
          },
          cashDetails: null,
          eftDetails: null,
          afterpayDetails: null,
        }),
        Cash: (payment) => ({
          paidBy: {
            userId: payment?.cashPayment?.paidBy?.id ?? null,
            fullName: payment?.cashPayment?.paidBy?.full_name ?? null,
            email: payment?.cashPayment?.paidBy?.email ?? null,
            phone: payment?.cashPayment?.paidBy?.phone ?? null,
            address: payment?.cashPayment?.paidBy?.customer?.address ?? null,
          },
          amount: payment?.cashPayment?.amount ?? 0,
          surcharge: 0,
          type: 'Cash',
          cardDetails: null,
          cashDetails: {
            status: payment.cashPayment?.handover_status ?? null,
          },
          eftDetails: null,
          afterpayDetails: null,
        }),
        EFTPOS: (payment) => ({
          paidBy: {
            userId: payment?.eftPayment?.paidBy?.id ?? null,
            fullName: payment?.eftPayment?.paidBy?.full_name ?? null,
            email: payment?.eftPayment?.paidBy?.email ?? null,
            phone: payment?.eftPayment?.paidBy?.phone ?? null,
            address: payment?.eftPayment?.paidBy?.customer?.address ?? null,
          },
          amount: payment?.eftPayment?.amount ?? 0,
          surcharge: payment?.eftPayment?.card_surcharge ?? 0,
          type: 'EFTPOS',
          cardDetails: null,
          cashDetails: null,
          eftDetails: payment.eftPayment && {
            reference: payment.eftPayment?.payment_reference ?? null,
          },
          afterpayDetails: null,
        }),
        Afterpay: (payment) => ({
          paidBy: {
            userId: payment?.afterPayment?.paidBy?.id ?? null,
            fullName: payment?.afterPayment?.paidBy?.full_name ?? null,
            email: payment?.afterPayment?.paidBy?.email ?? null,
            phone: payment?.afterPayment?.paidBy?.phone ?? null,
            address: payment?.afterPayment?.paidBy?.customer?.address ?? null,
          },
          amount: payment?.afterPayment?.amount ?? 0,
          surcharge: payment?.afterPayment?.surcharge ?? 0,
          type: 'Afterpay',
          cardDetails: null,
          cashDetails: null,
          eftDetails: null,
          afterpayDetails: payment.afterpayPayment && {
            token: payment.afterpayPayment?.token ?? null,
            payment_gateway_id: payment.afterpayPayment?.payment_gateway_id ?? null,
            status: payment.afterpayPayment?.status ?? null,
          },
        }),
      };

      this.incomeList = await this.payments.map((payment) => {

        let paymentId = payment?.id;
        let paymentType = payment?.type;
        let paymentPanel = payment?.panel;
        let paymentCode = payment?.code;
        let reference = payment?.reference;
        let serviceType = payment?.appointmentPayment?.appointment?.type ??
            payment?.orderPaymentsByReference?.appointment?.type ?? 'Individual';
        let technicianId = payment?.appointmentPayment?.appointment?.technicianAppointment?.technician?.id ??
            payment?.orderPaymentsByReference?.appointment?.technicianAppointment?.technician?.id ?? 0;
        let technicianFullName = payment?.appointmentPayment?.appointment?.technicianAppointment?.technician?.user?.full_name ??
            payment?.orderPaymentsByReference?.appointment?.technicianAppointment?.technician?.user?.full_name ?? 'Not Assigned';
        let paymentFor = payment.orderPaymentsByReference && !payment.appointmentPayment ? 'Order' :
            !payment.orderPaymentsByReference && payment.appointmentPayment ? 'Appointment' : null;
        const mappedPayment = detailMapping[payment.type]?.(payment);
        return {
          paymentId,
          paymentType,
          paymentPanel,
          paymentCode,
          technicianId,
          serviceType,
          technicianFullName,
          paymentFor,
          reference,
          ...mappedPayment,
        }
      });
      this.panelWiseAppointmentAndSales = await this.getPanelWiseAppointmentAndSales();
      this.technicianWiseAppointmentAndSales = await this.getTechnicianWiseAppointmentAndSales();
      this.paymentTypeWiseSales = await this.getPaymentTypeWiseSales();
      this.totals = await this.calculateTotals();

    },
    async calculateTotals() {
      return this.incomeList.reduce(
          (acc, income) => {
            if (income.paymentFor === "Appointment") {
              acc.appointmentTotal += income?.amount || 0;
              acc.remainingTotal += income?.remaining || 0;
            }
            if (income.paymentFor === "Order") {
              acc.orderTotal += income?.amount || 0;
            }
            if (income.paymentType === "Card") {
              acc.cardTotal += income?.amount || 0;
            }
            if (income.paymentType === "Cash") {
              acc.cashTotal += income?.amount || 0;
            }
            if (income.paymentType === "Afterpay") {
              acc.afterpayTotal += income?.amount || 0;
            }
            return acc;
          },
          {
            appointmentTotal: 0,
            remainingTotal: 0,
            orderTotal: 0,
            cardTotal: 0,
            cashTotal: 0,
            afterpayTotal: 0,
            eftTotal: 0,
          });
    },
    async getPaymentTypeWiseSales() {
      const aggregatedData = this.incomeList.reduce((acc, item) => {
        const paymentType = item.paymentType;
        const amount = item.amount;
        const surcharge = item.surcharge;
        if (!acc[paymentType]) {
          acc[paymentType] = {
            paymentType,
            count: 0,
            totalSales: 0,
            totalSurcharge: 0,
          };
        }
        if (amount >= 0) {
          acc[paymentType].count += 1;
          acc[paymentType].totalSales += amount;
          acc[paymentType].totalSurcharge += surcharge;
        }
        return acc;
      }, {});
      return Object.values(aggregatedData);
    },
    async getPanelWiseAppointmentAndSales() {
      const aggregateData = this.incomeList.reduce((acc, item) => {
        const paymentPanel = item.paymentPanel;
        const amount = item.amount;

        if (item.paymentFor === 'Appointment' && !acc[paymentPanel]) {
          acc[paymentPanel] = {
            paymentPanel,
            count: 0,
            totalSales: 0,
          };
        }


        if (item.paymentFor === 'Appointment') {
          acc[paymentPanel].count += 1; // Increment count only if paymentFor is 'appointment'
          acc[paymentPanel].totalSales += amount;  // Add the amount to total sales regardless of paymentFor
        }


        return acc;
      }, {});

      return Object.values(aggregateData);
    },
    async getTechnicianWiseAppointmentAndSales() {
      const aggregatedData = this.incomeList.reduce((acc, payment) => {
        const technicianName = payment.technicianFullName;

        if (!acc[technicianName]) {
          acc[technicianName] = {
            technicianName,
            appointments: 0,
            totalSales: 0,
          };
        }

        // Increment appointment count and total sales
        if (payment.paymentFor === "Appointment") {
          acc[technicianName].appointments += 1;
        }
        if (payment.paymentFor === "Order") {
          acc[technicianName].totalSales += payment.amount;
        }

        return acc;
      }, {});

      // Convert aggregated data to an array and sort by technician name
      return Object.values(aggregatedData).sort((a, b) =>
          a.technicianName.localeCompare(b.technicianName)
      );
    },

    async getPaymentList(paymentsParams) {
      this.loader(true);
      await this.getPayments(paymentsParams)
          .then(async (response) => {
            await this.generatePaymentTypeStatus();
            this.loader(false);
            if (response && response.message && response.status !== 200) {
              this.showToastMessage(response);
            }
          });
    },

    async getSettingList(settingParams) {
      await this.getSettings(settingParams);
    },

    getDisplayDate(date) {
      let newDate = date ? new Date(date) : new Date;
      return new Date(newDate).toLocaleDateString("en-AU", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
    },
  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList(this.getSettingsParams);
    await this.loader(false);
  }

}
</script>

<style scoped>
/*.badge {
  display: inline-block;
  padding: 4px 8px !important;
  font-size: 10px !important;
  font-weight: 400;
  line-height: 1 !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: baseline !important;
  border-radius: 0.375rem !important;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}*/

@media only screen and (max-width: 1337px) and (min-width: 320px) {
  .table {
    white-space: nowrap !important;
  }

}
</style>
