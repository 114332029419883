import auth from "@/router/middleware/auth";
import routesWithPrefix from "@/router/helper/routesWithPrefix";
import hasPermissions from "@/router/middleware/hasPermissions";


const routes = [
    ...routesWithPrefix('/app', [

        // Vacancy
        {
            path: '/vacancies/create',
            name: 'appVacancyCreate',
            component: () => import(/* webpackChunkName: "VacancyCreate" */ '@/views/backEnd/vacancies/VacancyCreate'),
            meta: {
                title: `Vacancies`,
                middleware: [
                    auth,
                    hasPermissions
                ],

                permissionGates: [
                    'vacancy-create',
                ],
            },
        },
        {
            path: '/vacancies',
            name: 'appVacancyList',
            component: () => import(/* webpackChunkName: "VacancyList" */ '@/views/backEnd/vacancies/VacancyList'),
            meta: {
                title: `Vacancies`,
                middleware: [
                    auth,
                    hasPermissions
                ],
                permissionGates: [
                    'vacancy-view-any',
                ],
            },
        },
        {
            path: '/vacancies/:id/edit',
            name: 'appVacancyEdit',
            component: () => import(/* webpackChunkName: "VacancyEdit" */ '@/views/backEnd/vacancies/VacancyEdit'),
            meta: {
                title: `Vacancies`,
                middleware: [
                    auth,
                    hasPermissions
                ],
                permissionGates: [
                    'vacancy-update',
                ],
            },
        },

        // Admin
        {
            path: '/admins/create',
            name: 'appAdminCreate',
            component: () => import(/* webpackChunkName: "AdminCreate" */ '@/views/backEnd/users/admins/AdminCreate'),
            meta: {
                title: `Admins`,
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'admin-create',
                ],
            },
        },
        {
            path: '/admins',
            name: 'appAdminList',
            component: () => import(/* webpackChunkName: "AdminList" */ '@/views/backEnd/users/admins/AdminList'),
            meta: {
                title: `Admins`,
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'admin-view-any',
                ],
            },
        },
        {
            path: '/admins/:id',
            name: 'appAdminView',
            component: () => import(/* webpackChunkName: "AdminView" */ '@/views/backEnd/users/admins/AdminView'),
            meta: {
                title: `Admins`,
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'admin-view',
                ],
            },
        },

        // partner
        {
            path: '/partners/create',
            name: 'appPartnerCreate',
            component: () => import(/* webpackChunkName: "PartnerCreate" */ '@/views/backEnd/users/partners/PartnerCreate'),
            meta: {
                title: `Partners`,
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'partner-create',
                ],
            },
        },
        {
            path: '/partners',
            name: 'appPartnerList',
            component: () => import(/* webpackChunkName: "PartnerList" */ '@/views/backEnd/users/partners/PartnerList'),
            meta: {
                title: `Partners`,
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'partner-view-any',
                ],
            },
        },
        {
            path: '/partners/:id',
            name: 'appPartnerView',
            component: () => import(/* webpackChunkName: "PartnerView" */ '@/views/backEnd/users/partners/PartnerView'),
            meta: {
                title: `Admins`,
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'partner-view',
                ],
            },
        },

        // Super Admin
        {
            path: '/super-admins',
            name: 'appSuperAdminList',
            component: () => import(/* webpackChunkName: "SuperAdminList" */ '@/views/backEnd/users/superAdmins/SuperAdminList'),
            meta: {
                title: `Super Admins`,
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'admin-view-any',
                ],
            },
        },


        // Customer
        {
            path: '/customers/create',
            name: 'appCustomerCreate',
            props: true,
            component: () => import(/* webpackChunkName: "CustomerCreate" */ '@/views/backEnd/users/customers/CustomerCreate'),
            meta: {
                tile: 'Customers',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'customer-create',
                ],
            },
        },
        {
            path: '/customers',
            name: 'appCustomerList',
            component: () => import(/* webpackChunkName: "CustomerList" */ '@/views/backEnd/users/customers/CustomerList'),
            meta: {
                tile: 'Customers',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'customer-view-any',
                ],
            },
        },
        {
            path: '/customers/:id',
            name: 'appCustomerView',
            component: () => import(/* webpackChunkName: "CustomerView" */ '@/views/backEnd/users/customers/CustomerView'),
            meta: {
                tile: 'Customers',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'customer-view',
                ],
            },
        },
        {
            path: '/customers/exports',
            name: 'appCustomerExports',
            component: () => import(/* webpackChunkName: "CustomerExport" */ '@/views/backEnd/users/customers/CustomerExport'),
            meta: {
                tile: 'Customers',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'customer-list-export',
                ],
            },
        },

        // Technician
        {
            path: '/technicians/create',
            name: 'appTechnicianCreate',
            component: () => import(/* webpackChunkName: "TechnicianCreate" */ '@/views/backEnd/users/technicians/TechnicianCreate'),
            meta: {
                title: `Technicians`,
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'technician-create'
                ],
            },
        },
        {
            path: '/technicians',
            name: 'appTechnicianList',
            component: () => import(/* webpackChunkName: "TechnicianList" */ '@/views/backEnd/users/technicians/TechnicianList'),
            meta: {
                title: `Technicians`,
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'technician-view-any'
                ],
            },
        },
        {
            path: '/technicians/:id',
            name: 'appTechnicianView',
            component: () => import(/* webpackChunkName: "TechnicianView" */ '@/views/backEnd/users/technicians/TechnicianView'),
            meta: {
                title: `Technicians`,
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'technician-view'
                ],
            },
        }, //TODO:: need to update the inside component permission.
        {
            path: '/technicians/:id/schedule',
            name: 'appTechnicianScheduleEdit',
            component: () => import(/* webpackChunkName: "TechnicianScheduleEdit" */ '@/views/backEnd/users/technicians/TechnicianScheduleEdit'),
            meta: {
                title: `Technicians`,
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'technician-update',
                ],
            },
        },

        // Team Lead
        {
            path: '/team-leads',
            name: 'appTeamLeadList',
            component: () => import(/* webpackChunkName: "TeamLeadList" */ '@/views/backEnd/users/teamLeads/TeamLeadList'),
            meta: {
                title: 'Team Leads',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'team-lead-view-any'
                ],
            },
        },

        // On-Site Technician
        {
            path: '/on-site-technicians',
            name: 'appOnSiteTechnicianList',
            component: () => import(/* webpackChunkName: "OnSiteTechnicianList" */ '@/views/backEnd/users/onSiteTechnicians/OnSiteTechnicianList'),
            meta: {
                title: 'On-Site Technicians',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'on-site-technician-view-any'
                ],
            },
        },

        // Remote Technician
        {
            path: '/remote-technicians',
            name: 'appRemoteTechnicianList',
            component: () => import(/* webpackChunkName: "RemoteTechnicianList" */ '@/views/backEnd/users/remoteTechnicians/RemoteTechnicianList'),
            meta: {
                title: 'Remote Technicians',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'remote-technician-view-any'
                ],
            },
        },

        // Manager
        {
            path: '/managers/create',
            name: 'appManagerCreate',
            component: () => import(/* webpackChunkName: "ManagerCreate" */ '@/views/backEnd/users/managers/ManagerCreate'),
            meta: {
                title: 'Managers',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'manager-create'
                ],
            },

        },
        {
            path: '/managers',
            name: 'appManagerList',
            component: () => import(/* webpackChunkName: "ManagerList" */ '@/views/backEnd/users/managers/ManagerList'),
            meta: {
                title: 'Managers',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'manager-view-any'
                ],
            },
        },
        {
            path: '/managers/:id',
            name: 'appManagerView',
            component: () => import(/* webpackChunkName: "editManager" */ '../views/backEnd/users/managers/ManagerView'),
            meta: {
                title: 'Managers',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'manager-view'
                ],
            },
        }, //TODO:: need to update the inside component permission.

        // Store Manager
        {
            path: '/store-managers',
            name: 'appStoreManagerList',
            component: () => import(/* webpackChunkName: "StoreManagerList" */ '@/views/backEnd/users/storeManagers/StoreManagerList'),
            meta: {
                title: 'Store Managers',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'store-manager-view-any'
                ],
            },
        },

        // Franchisee
        {
            path: '/franchisees/create',
            name: 'appFranchiseeCreate',
            component: () => import(/* webpackChunkName: "FranchiseeCreate" */ '@/views/backEnd/users/franchisees/FranchiseeCreate'),
            meta: {
                tile: 'Franchisees',
                middleware: [
                    auth,
                    hasPermissions
                ],
                permissionGates: [
                    'franchisee-create',
                ]
            },
        },
        {
            path: '/franchisees',
            name: 'appFranchiseeList',
            component: () => import(/* webpackChunkName: "FranchiseeList" */ '@/views/backEnd/users/franchisees/FranchiseeList'),
            meta: {
                title: 'Franchisees',
                middleware: [
                    auth,
                    hasPermissions
                ],
                permissionGates: [
                    'franchisee-view-any',
                ]

            },
        },
        {
            path: '/franchisees/:id',
            name: 'appFranchiseeView',
            component: () => import(/* webpackChunkName: "FranchiseeView" */ '@/views/backEnd/users/franchisees/FranchiseeView'),
            meta: {
                title: 'Franchisees',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'franchisee-view',
                ],
            },
        },


        {
            path: '/forgot-password',
            name: 'appForgotPassword',
            props: true,
            component: () => import(/* webpackChunkName: "forgotPassword" */ '@/views/backEnd/auth/ForgotPassword.vue'),
            meta: {
                title: `Forgot Password`,
            },
        },

        {
            path: '/reset-password',
            name: 'appResetPassword',
            props: true,
            component: () => import(/* webpackChunkName: "appResetPassword" */ '@/views/backEnd/auth/ResetPassword'),
            meta: {
                title: `Reset Password`,
            },
        },
        {
            path: '/login',
            name: 'appLogin',
            props: true,
            component: () => import(/* webpackChunkName: "appLogin" */ '@/views/backEnd/auth/LogIn.vue'),
            meta: {
                title: `Login`,
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'customer-care-view'
                ],
            },
        }, //TODO:: need to update the inside component permission.

        {
            path: '/customer-cares/create',
            name: 'appCustomerCareCreate',
            component: () => import(/* webpackChunkName: "CustomerCareCreate" */ '@/views/backEnd/users/customerCares/CustomerCareCreate'),
            meta: {
                title: 'Customer Cares',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'customer-care-view-any'
                ],
            },
        },
        {
            path: '/customer-cares/:id',
            name: 'appCustomerCareView',
            component: () => import(/* webpackChunkName: "CustomerCareView" */ '@/views/backEnd/users/customerCares/CustomerCareView'),
            meta: {
                title: 'Customer Cares',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'customer-care-view'
                ],
            },
        }, //TODO:: need to update the inside component permission.
        {
            path: '/customer-cares',
            name: 'appCustomerCareList',
            component: () => import(/* webpackChunkName: "CustomerCareList" */ '@/views/backEnd/users/customerCares/CustomerCareList'),
            meta: {
                title: 'Customer Cares',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'customer-care-view-any'
                ],
            },
        },

        // Lab User
        {
            path: '/lab-users/create',
            name: 'appLabUserCreate',
            component: () => import(/* webpackChunkName: "LabUserCreate" */ '@/views/backEnd/users/labUsers/LabUserCreate'),
            meta: {
                tile: 'Lab Users',
                middleware: [
                    auth,
                    hasPermissions

                ],
                permissionGates: [
                    'lab-user-create',
                ]

            },
        },
        {
            path: '/lab-users',
            name: 'appLabUserList',
            component: () => import(/* webpackChunkName: "LabUserList" */ '@/views/backEnd/users/labUsers/LabUserList'),
            meta: {
                title: 'Lab Users',
                middleware: [
                    auth,
                    hasPermissions

                ],
                permissionGates: [
                    'lab-user-view-any',
                ]

            },
        },
        {
            path: '/lab-users/:id',
            name: 'appLabUserView',
            component: () => import(/* webpackChunkName: "LabUserView" */ '@/views/backEnd/users/labUsers/LabUserView'),
            meta: {
                title: 'Lab Users',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'lab-user-view',
                ],
            },
        },

        //Vendor
        {
            path: '/vendors/create',
            name: 'appVendorCreate',
            component: () => import(/* webpackChunkName: "VendorCreate" */ '@/views/backEnd/users/vendors/VendorCreate'),
            meta: {
                title: 'Vendors',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'vendor-create',
                ],
            },

        },
        {
            path: '/vendors',
            name: 'appVendorList',
            component: () => import(/* webpackChunkName: "VendorList" */ '@/views/backEnd/users/vendors/VendorList'),
            meta: {
                title: 'Vendors',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'vendor-view-any',
                ],
            },

        },
        {
            path: '/vendors/:id',
            name: 'appVendorView',
            component: () => import(/* webpackChunkName: "VendorView" */ '@/views/backEnd/users/vendors/VendorView'),
            meta: {
                title: 'Vendors',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'vendor-view',
                ],
            },

        },

        // Role
        {
            path: '/roles/create',
            name: 'appRoleCreate',
            component: () => import(/* webpackChunkName: "RoleCreate" */ '@/views/backEnd/roles/RoleCreate'),
            meta: {
                title: 'Roles',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'role-create',
                ],
            },

        },
        {
            path: '/roles',
            name: 'appRoleList',
            component: () => import(/* webpackChunkName: "RoleList" */ '@/views/backEnd/roles/RoleList'),
            meta: {
                title: 'Roles',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'role-view-any',
                ],
            },

        },
        {
            path: '/roles/:id',
            name: 'appRoleView',
            component: () => import(/* webpackChunkName: "RoleView" */ '@/views/backEnd/roles/RoleView'),
            meta: {
                title: 'Roles',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'role-view',
                ],
            },

        },
        {
            path: '/roles/:id/edit',
            name: 'appRoleEdit',
            component: () => import(/* webpackChunkName: "RoleEdit" */ '@/views/backEnd/roles/RoleEdit'),
            meta: {
                title: 'Roles',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'role-update',
                ],
            },
        },

        // Service Category
        {
            path: '/service-categories',
            name: 'appServiceCategoryList',
            component: () => import(/* webpackChunkName: "ServiceCategoryList" */ '@/views/backEnd/serviceCategories/ServiceCategoryList'),
            meta: {
                title: 'Service Categories',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'service-category-view-any',
                ],
            },
        },

        // Service
        {
            path: '/services/create',
            name: 'appServiceCreate',
            component: () => import(/* webpackChunkName: "ServiceList" */ '@/views/backEnd/services/ServiceCreate'),
            meta: {
                title: 'Services',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'service-create',
                ],
            },
        },
        {
            path: '/services',
            name: 'appServiceList',
            component: () => import(/* webpackChunkName: "ServiceList" */ '@/views/backEnd/services/ServiceList'),
            meta: {
                title: 'Service',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'service-view-any',
                ],
            },
        },

        {
            path: '/services/:id',
            name: 'appServiceView',
            component: () => import(/* webpackChunkName: "ServiceView" */ '@/views/backEnd/services/ServiceView'),
            meta: {
                title: 'Services',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'service-view',
                ],
            },
        },

        // Service Tip
        {
            path: '/service-tips',
            name: 'appServiceTipList',
            component: () => import(/* webpackChunkName: "ServiceTipList" */ '@/views/backEnd/serviceTips/ServiceTipList'),
            meta: {
                title: 'Service Tips',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'service-tip-view-any',
                ],
            },
        },

        // Product Category
        {
            path: '/product-categories/create',
            name: 'appProductCategoryCreate',
            component: () => import(/* webpackChunkName: "ProductCategoryCreate" */ '@/views/backEnd/productCategories/ProductCategoryCreate'),
            meta: {
                title: 'Product Categories',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'product-category-create',
                ],
            }
        },
        {
            path: '/product-categories',
            name: 'appProductCategoryList',
            component: () => import(/* webpackChunkName: "ProductCategoryList" */ '@/views/backEnd/productCategories/ProductCategoryList'),
            meta: {
                title: 'Product Categories',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'product-category-view-any',
                ],
            }
        },

        {
            path: '/forgot-password',
            name: 'appForgotPassword',
            props: true,
            component: () => import(/* webpackChunkName: "forgotPassword" */ '@/views/backEnd/auth/ForgotPassword.vue'),
            meta: {
                title: `Forgot Password`,
            },
        },

        {
            path: '/reset-password',
            name: 'appResetPassword',
            props: true,
            component: () => import(/* webpackChunkName: "appResetPassword" */ '@/views/backEnd/auth/ResetPassword'),
            meta: {
                title: `Reset Password`,
            },
        },

        {
            path: '/dashboard',
            name: 'appDashboard',
            component: () => import(/* webpackChunkName: "appDashboard" */ '@/views/backEnd/dashboards/AppDashboard.vue'),
            meta: {
                title: 'Dashboard',
                middleware: [
                    auth
                ],
            },
        },


        // Appointment
        {
            path: '/appointments/create',
            name: 'appAppointmentCreate',
            props: true,
            component: () => import(/* webpackChunkName: "appointmentCreate" */ '@/views/backEnd/appointments/appointmentCreate/AppointmentCreate'),
            meta: {
                title: `Appointments`,
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'appointment-create',
                ],
            },
        },

        {
            path: '/appointments',
            name: 'appAppointmentList',
            props: true,
            component: () => import(/* webpackChunkName: "appointmentList" */ '@/views/backEnd/appointments/appointmentList/AppointmentList.vue'),
            meta: {
                title: `Appointments`,
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'appointment-view-any',
                ],
            },
        },

        {
            path: '/appointment/cost-estimate',
            name: 'appAppointmentCostEstimate',
            component: () => import(/* webpackChunkName: "appAppointmentCostEstimate" */ '@/views/backEnd/appointments/appointmentCostEstimate/AppointmentCostEstimate.vue'),
            meta: {
                title: 'Cost Estimate',
                middleware: [
                    auth,

                ],
                permissionGates: [
                    'appointment-create',
                ],
            },
        },
        // appointment report starts
        {
            path: '/report/appointments',
            name: 'appAppointmentReport',
            component: () => import(/* webpackChunkName: "appAppointmentReport" */ '@/views/backEnd/reports/AppointmentReport.vue'),
            meta: {
                title: 'Report | Appointment',
                middleware: [
                    auth,

                ],
                permissionGates: [
                    '',
                ],
            },
        },
        {
            path: '/call-request/create',
            name: 'appCallRequestCreate',
            component: () => import(/* webpackChunkName: "appCallRequestCreate" */ '@/views/backEnd/CallRequest/CallRequestCreate.vue'),
            meta: {
                title: 'Call Request Create',
                middleware: [
                    auth,

                ],
                permissionGates: [
                    'call-request-create',
                ],
            },
        },
        {
            path: '/call-request/list',
            name: 'appCallRequestList',
            component: () => import(/* webpackChunkName: "appCallRequestList" */ '@/views/backEnd/CallRequest/CallRequestList.vue'),
            meta: {
                title: 'Call Request List',
                middleware: [
                    auth,

                ],
                permissionGates: [
                    'call-request-view-any',
                ],
            },
        },
        // CallRequestList

        {
            path: '/appointments/exports/customers',
            name: 'appAppointmentExportsCustomers',
            component: () => import(/* webpackChunkName: "appointmentClosers" */ '@/views/backEnd/appointments/AppointmentCustomersExport'),
            meta: {
                title: `Appointment Customer Export`,
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'appointment-customer-list-export',
                ],
            },
        },

        {
            path: '/appointments/invoice/:uuid',
            name: 'appAppointmentInvoice',

            component: () => import(/* webpackChunkName: "appointmentInvoice" */ '@/views/backEnd/appointments/appointmentList/includes/AppointmentInvoice.vue'),
            meta: {
                middleware: [
                    auth
                ],
            },
        },

        {
            path: '/appointments/closers',
            name: 'appAppointmentClosers',
            component: () => import(/* webpackChunkName: "appointmentClosers" */ '@/views/backEnd/appointments/appointmentClosers/AppointmentClosers'),
            meta: {
                title: `Appointment Closers`,
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'appointment-consent-view-any',
                ],
            },
        },
        {
            path: '/appointments/service-agreements',
            name: 'appointmentServiceAgreements',
            component: () => import(/* webpackChunkName: "appointmentServiceAgreements" */ '@/views/backEnd/appointments/serviceAgreements/AppointmentServiceAgreements'),
            meta: {
                title: `Appointment Service Agreement`,
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'appointment-consent-view-any',
                ],
            },
        },

        {
            path: '/leave-applications',
            name: 'leaveApplications',
            component: () => import(/* webpackChunkName: "leaveApplications" */ '@/views/backEnd/LeaveApplications/LeaveApplicationList.vue'),
            meta: {
                middleware: [
                    auth
                ],
            },
        },

        // **** warehouse route starts ****

        {
            path: '/warehouses',
            name: 'appWarehouseList',
            component: () => import('@/views/backEnd/Warehouse/ListWarehouse'),
            meta: {
                middleware: [
                    auth
                ],
                permissionGates: [
                    'warehouse-view-any'
                ]
            },
        },
        {
            path: '/warehouses/create',
            name: 'appWarehouseCreate',
            component: () => import('@/views/backEnd/Warehouse/CreateWarehouse'),
            meta: {
                middleware: [
                    auth
                ],
                permissionGates: [
                    'warehouse-create'
                ]
            },
        },
        {
            path: '/warehouses/:id/edit',
            name: 'appWarehouseView',
            component: () => import('@/views/backEnd/Warehouse/EditWarehouse'),
            meta: {
                middleware: [
                    auth
                ],
                permissionGates: [
                    'warehouse-view'
                ]
            },
        },

        // **** warehouse route ends ****

        //  **** product items route start ****
        {
            path: '/product/items/create',
            name: 'appProductItemCreate',
            component: () => import('@/views/backEnd/products/productItems/CreateProductItem'),
            meta: {
                title: 'Product Item Create',
                middleware: [
                    auth
                ],
                permissionGates: [
                    'product-item-create'
                ]
            },
        },
        {
            path: '/product/items',
            name: 'appProductItemList',
            component: () => import('@/views/backEnd/products/productItems/ListProductItem'),
            meta: {
                title: 'Product Item List',
                middleware: [
                    auth
                ],
                permissionGates: [
                    'product-item-view-any'
                ]
            },
        },
        {
            path: '/product/items/:id/view',
            name: 'appProductItemView',
            component: () => import('@/views/backEnd/products/productItems/ViewProductItem'),
            meta: {
                title: 'Product Item View',
                middleware: [
                    auth
                ],
                permissionGates: [
                    'product-item-view'
                ]
            },
        },
        //  **** product items route ends ****

        // **** product item tracking route start  ****
        {
            path: '/product-item-tracking',
            name: 'appProductItemTracking',
            component: () => import('@/views/backEnd/products/productItemTracking/ListProductItemTracking'),
            meta: {
                title: 'Product Item Tracking',
                middleware: [
                    auth
                ],
                permissionGates: [
                    'product-item-tracking-view-any'
                ]
            },
        },

        // **** product item tracking route end  ****

        // **** coupon route start   ****
        {
            path: '/marketing/coupons',
            name: 'appCouponList',
            component: () => import('@/views/backEnd/coupons/CouponList.vue'),
            meta: {
                mata: 'Coupons',
                middleware: [
                    auth,
                    hasPermissions
                ],
                permissionGates: [
                    'coupon-view-any'
                ]
            },
        },
        {
            path: '/marketing/coupons/create',
            name: 'appCouponCreate',
            component: () => import('@/views/backEnd/coupons/CouponCreate.vue'),
            meta: {
                mata: 'Coupons',
                middleware: [
                    auth,
                    hasPermissions
                ],
                permissionGates: [
                    'coupon-create'
                ]
            },
        },
        {
            path: '/marketing/coupons/coupon-usages',
            name: 'appCouponUsageView',
            component: () => import('@/views/backEnd/coupons/CouponUsageList.vue'),
            meta: {
                mata: 'Coupons',
                middleware: [
                    auth,
                    hasPermissions
                ],
                permissionGates: [
                    'coupon-usage-view-any'
                ]
            },
        },
        {
            path: '/marketing/coupons-edm/:id',
            name: 'appCouponEdm',
            component: () => import('@/views/backEnd/coupons/CouponEdm.vue'),
            meta: {
                title: 'Coupon Edm',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'coupon-create'
                ]
            },
        },
        // **** coupon route end    ****

        // **** sms marketing route end    ****
        {
            path: '/marketing/sms-dm',
            name: 'appSmsDmSend',
            component: () => import('@/views/backEnd/smsMarketings/SmsDm.vue'),
            meta: {
                title: 'SMS Digital Marketing',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'sms-marketing-send'
                ]
            },
        },
        // **** sms marketing route end    ****

        // **** products routes starts ****
        {
            path: '/products',
            name: 'appProductList',
            component: () => import('@/views/backEnd/products/ProductList'),
            meta: {
                title: 'Products',
                middleware: [
                    auth
                ],
                permissionGates: [
                    'product-view-any'
                ]
            },
        },
        {
            path: '/products/create',
            name: 'appProductCreate',
            component: () => import('@/views/backEnd/products/ProductCreate.vue'),
            meta: {
                title: 'Products',
                middleware: [
                    auth
                ],
                permissionGates: [
                    'product-create'
                ]
            },
        },

        {
            path: '/products/:id',
            name: 'appProductView',
            component: () => import('@/views/backEnd/products/ProductView.vue'),
            meta: {
                title: 'Products',
                middleware: [
                    auth
                ],
                permissionGates: [
                    'product-view'
                ]
            },
        },
        // **** products routes ends ****

        // **** customer opinions route start  ****

        {
            path: '/customer-opinions',
            name: 'appCustomerOpinionList',
            component: () => import('@/views/backEnd/customerOpinions/CustomerOpinionList'),
            meta: {
                middleware: [
                    auth
                ],
                permissionGates: [
                    'customer-opinion-view-any'
                ]
            },
        },
        // **** customer opinions route end   ****

        // ***  order route starts ****
        {
            path: '/orders',
            name: 'appOrderList',
            component: () => import('@/views/backEnd/Orders/AllOrder/AllOrder.vue'),
            meta: {
                title: 'Order list',
                middleware: [
                    auth
                ],
                permissionGates: [
                    'order-view-any'
                ]
            },
        },
        {
            path: '/orders/:id/view',
            name: 'appOrderView',
            component: () => import('@/views/backEnd/Orders/OrderDetails'),
            meta: {
                middleware: [
                    auth
                ],
                permissionGates: [
                    'order-view'
                ]
            },
        },
        {
            path: '/orders/:id/procure',
            name: 'appOrderProcure',
            component: () => import('@/views/backEnd/Orders/OrderProcure'),
            meta: {
                middleware: [
                    auth
                ],
                permissionGates: [
                    'order-view'
                ]
            },
        },
        {
            path: '/order/checkout',
            name: 'appOrderCheckout',
            component: () => import('@/views/backEnd/Orders/OrderCheckout'),
            meta: {
                middleware: [
                    auth
                ],
                permissionGates: [
                    'order-create'
                ]
            },
        },


        {
            path: '/orders/create',
            name: 'appOrderCreate',
            props: true,
            component: () => import('@/views/backEnd/Orders/OrderCreate.vue'),
            meta: {
                middleware: [
                    auth
                ],
                permissionGates: [
                    'order-create'
                ]
            },
        },
        {
            path: '/orders/invoice/:uuid',
            name: 'appOrderInvoice',
            component: () => import('@/views/backEnd/Orders/AllOrder/includes/OrderInvoice/OrderInvoice.vue'),
            meta: {
                middleware: [
                    auth
                ],
                permissionGates: [
                    'order-invoice-view'
                ]
            },
        },
        {
            path: '/orders/updateProcess/:id',
            name: 'appOrderUpdateProcess',
            component: () => import('@/views/backEnd/Orders/OrderUpdateAfterProcess.vue'),
            meta: {
                middleware: [
                    auth
                ],
                permissionGates: [
                    'order-view'
                ]
            },
        },
        {
            path: '/orders/update-before-process/:id',
            name: 'appOrderUpdateBeforeProcess',
            component: () => import('@/views/backEnd/Orders/EditOrderBeforeProcess/OrderCreateEdit.vue'),
            meta: {
                middleware: [
                    auth
                ],
                permissionGates: [
                    'order-create'
                ]
            },
        },
        // ****  order jobs starts ****
        {
            path: '/orderjobs',
            name: 'appOrderJobList',
            component: () => import('@/views/backEnd/orderJobs/OrderJobsList.vue'),
            meta: {
                middleware: [
                    auth
                ],
                permissionGates: [
                    'order-job-view-any'
                ]
            },

        },

        // ****     payment route starts ****
        {
            path: '/account/finance/payments',
            name: 'appAccountFinancePaymentsList',
            component: () => import('@/views/backEnd/AccountAndFinance/Finance/AllPayments/AllPayments.vue'),
            meta: {
                middleware: [
                    auth
                ],
                permissionGates: [
                    'payment-view-any'
                ]
            },
        },
        // ****     payment route ends ****

        // **** refunds route Start ****
        {
            path: '/refunds',
            name: 'appRefundList',
            component: () => import('@/views/backEnd/refunds/RefundList'),
            meta: {
                middleware: [
                    auth
                ],
                permissionGates: [
                    'refund-view-any'
                ]
            },
        },
        {
            path: '/refunds/create',
            name: 'appRefundCreate',
            component: () => import('@/views/backEnd/refunds/RefundCreate'),
            meta: {
                middleware: [
                    auth
                ],
                permissionGates: [
                    'refund-create'
                ]
            },
        },
        // **** refunds route End ****

        // **** geeks commission payment route start ****
        {
            path: '/technician-commission-payments',
            name: 'appTechnicianCommissionPaymentList',
            component: () => import('@/views/backEnd/technicianCommissionPayments/TechnicianCommissionPaymentList.vue'),
            meta: {
                middleware: [
                    auth
                ],
                permissionGates: [
                    'technician-commission-payment-view-any',
                ]
            },
        },
        {
            path: '/technician-commission-payments/create',
            name: 'appTechnicianCommissionPaymentCreate',
            component: () => import('@/views/backEnd/technicianCommissionPayments/TechnicianCommissionPaymentCreate.vue'),
            meta: {
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'technician-commission-payment-create',
                ]
            },
        },
        {
            path: '/technician-commission-payments/:id/view',
            name: 'appTechnicianPaymentView',
            component: () => import('@/views/backEnd/technicianCommissionPayments/TechnicianCommissionPaymentView.vue'),
            meta: {
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'technician-commission-payment-view',
                ]
            },
        },
        {
            path: '/technician-commission-payments/:id/print',
            name: 'appTechnicianPaymentPrint',
            component: () => import('@/views/backEnd/technicianCommissionPayments/TechnicianCommissionPaymentPrint.vue'),
            meta: {
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'technician-commission-payment-view',
                ]
            },
        },

        // email templates
        {
            path: '/email-templates',
            name: 'emailTemplates',
            component: () => import(/* webpackChunkName: "emailTemplates" */ '@/views/backEnd/EmailTemplate/EmailTemplateList.vue'),
            meta: {
                title: 'Email Templates',
                middleware: [
                    auth
                ],
            },

        },

        {
            path: '/email-templates/:id/edit',
            name: 'emailTemplateEdit',
            component: () => import(/* webpackChunkName: "emailTemplateEdit" */ '@/views/backEnd/EmailTemplate/EmailTemplateEdit.vue'),
            meta: {
                title: 'Email Templates',
                middleware: [
                    auth
                ],
            },

        },

        // Sms templates
        {
            path: '/sms-templates',
            name: 'smsTemplates',
            component: () => import(/* webpackChunkName: "smsTemplates" */ '@/views/backEnd/SmsTemplates/SmsTemplateList.vue'),
            meta: {
                title: 'Sms Templates',
                middleware: [
                    auth
                ],
            },

        },

        {
            path: '/sms-templates/:id/edit',
            name: 'smsTemplateEdit',
            component: () => import(/* webpackChunkName: "smsTemplateEdit" */ '@/views/backEnd/SmsTemplates/SmsTemplateEdit.vue'),
            meta: {
                title: 'Sms Templates',
                middleware: [
                    auth
                ],
            },

        },

        // *** settings start

        // *** default setting start
        {
            path: '/settings/default/company-settings',
            name: 'settingsDefaultCompanyList',
            component: () => import(/* webpackChunkName: "settingsDefaultCompanyList" */ '@/views/backEnd/settings/default/companyDetails/SettingDefaultCompanyDetailList.vue'),
            meta: {
                title: 'Company Settings',
                middleware: [
                    auth
                ],
                permissionGates: ['setting-default-view-any']
            },
        },
        {
            path: '/settings/default/mob-app-settings',
            name: 'settingsDefaultMobAppList',
            component: () => import(/* webpackChunkName: "settingsDefaultMobAppList" */ '@/views/backEnd/settings/default/mobApps/SettingDefaultMobAppList.vue'),
            meta: {
                title: 'Mob App Settings',
                middleware: [
                    auth
                ],
                permissionGates: ['setting-default-view-any']
            },
        },
        {
            path: '/settings/default/social-link-settings',
            name: 'settingsDefaultSocialLinkList',
            component: () => import(/* webpackChunkName: "settingsDefaultSocialLinkList" */ '@/views/backEnd/settings/default/socialLinkDetails/SettingDefaultSocialLinkDetailList.vue'),
            meta: {
                title: 'Social Link Settings',
                middleware: [
                    auth
                ],
                permissionGates: ['setting-default-view-any']
            },
        },
        {
            path: '/settings/default/system-settings',
            name: 'settingsDefaultSystemList',
            component: () => import(/* webpackChunkName: "settingsDefaultSystemList" */ '@/views/backEnd/settings/default/systemDetails/SettingDefaultSystemDetailList.vue'),
            meta: {
                title: 'System Settings',
                middleware: [
                    auth
                ],
                permissionGates: ['setting-default-view-any']
            },
        },
        {
            path: '/settings/default/website-settings',
            name: 'settingsDefaultWebsiteList',
            component: () => import(/* webpackChunkName: "settingsDefaultWebsiteList" */ '@/views/backEnd/settings/default/websites/SettingDefaultWebsiteList.vue'),
            meta: {
                title: 'Website Settings',
                middleware: [
                    auth
                ],
                permissionGates: ['setting-default-view-any']
            },
        },

        // *** google map setting start
        {
            path: '/settings/google-map',
            name: 'settingsGoogleMapList',
            component: () => import(/* webpackChunkName: "settingsGoogleMapList" */ '@/views/backEnd/settings/googleMaps/SettingGoogleMapList.vue'),
            meta: {
                title: 'Google Map settings',
                middleware: [
                    auth
                ],
                permissionGates: ['setting-google-map-view-any']
            },
        },

        // *** notification setting start
        {
            path: '/settings/notifications',
            name: 'settingsNotificationList',
            component: () => import(/* webpackChunkName: "settingsNotificationList" */ '@/views/backEnd/settings/notification/NotificationSetting.vue'),
            meta: {
                title: 'Notification setting',
                middleware: [
                    auth
                ],
                permissionGates: ['setting-notification-view-any']
            },
        },
        // *** coupon setting start
        {
            path: '/settings/coupons',
            name: 'settingsCouponList',
            component: () => import('@/views/backEnd/settings/coupon/SettingCouponList.vue'),
            meta: {
                title: 'Coupon setting',
                middleware: [
                    auth
                ],
            },
        },
        {
            path: '/settings/appointment',
            name: 'settingsAppointmentList',
            component: () => import(/* webpackChunkName: "settingsAppointmentList" */ '@/views/backEnd/settings/appointments/SettingAppointmentList.vue'),
            meta: {
                title: 'Appointment setting',
                middleware: [
                    auth
                ],
                permissionGates: ['setting-appointment-update']
            },
        },

        // *** appointment settings
        {
            path: '/settings/appointment-old',
            name: 'settingsAppointmentOld',
            component: () => import(/* webpackChunkName: "appointmentSetting" */ '@/views/backEnd/settings/appointments/AppointmentSettingOld.vue'),
            meta: {
                title: 'Appointment setting',
                middleware: [
                    auth
                ],
                permissionGates: ['setting-appointment-update']
            },
        },




        // *** order setting starts

        {
            path: '/settings/order',
            name: 'settingsOrderList',
            component: () => import(/* webpackChunkName: "settingsOrderList" */ '@/views/backEnd/settings/order/SettingOrderList.vue'),
            meta: {
                title: 'Order setting',
                middleware: [
                    auth
                ],
                // permissionGates: [''] TODO::HAVE TO ADD PERMISSION
            },
        },

        // *** vacancy setting

        {
            path: '/settings/vacancy',
            name: 'settingsVacancyList',
            component: () => import(/* webpackChunkName: "settingsVacancyList" */ '@/views/backEnd/settings/vacancies/SettingVacancyList'),
            meta: {
                title: 'Vacancy setting',
                middleware: [
                    auth
                ],
                // permissionGates: [''] TODO::HAVE TO ADD PERMISSION
            },
        },

        // users:customer settings
        {
            path: '/settings/customer',
            name: 'settingsCustomerList',
            component: () => import(/* webpackChunkName: "settingsCustomerList" */ '@/views/backEnd/settings/customer/SettingCustomerList.vue'),
            meta: {
                title: 'Customer Settings',
                middleware: [
                    auth
                ],
            },

        },

        // service settings

        {
            path: '/settings/service',
            name: 'settingsServiceList',
            component: () => import(/* webpackChunkName: "settingsServiceList" */ '@/views/backEnd/settings/service/SettingServiceList.vue'),
            meta: {
                title: 'Service Settings',
                middleware: [
                    auth
                ],
            },
        },

        // call request settings
        {
            path: '/settings/call-request',
            name: 'settingsCallRequestList',
            component: () => import(/* webpackChunkName: "settingsCallRequestList" */ '@/views/backEnd/settings/callRequest/SettingCallRequestList.vue'),
            meta: {
                title: 'Call Request Settings',
                middleware: [
                    auth
                ],
            },
        },

        // Technician settings
        {
            path: '/settings/technician',
            name: 'settingsTechnician',
            component: () => import(/* webpackChunkName: "settingsTechnician" */ '@/views/backEnd/settings/technician/TechnicianSetting.vue'),
            meta: {
                title: 'Technician Settings',
                middleware: [
                    auth
                ],
            },
        },

        // Product settings
        {
            path: '/settings/product',
            name: 'settingsProductList',
            component: () => import(/* webpackChunkName: "settingsProductList" */ '@/views/backEnd/settings/products/SettingProductList.vue'),
            meta: {
                title: 'Product Settings',
                middleware: [
                    auth
                ],
            },
        },

        // Product item settings
        {
            path: '/settings/product-item',
            name: 'settingsProductItemList',
            component: () => import(/* webpackChunkName: "settingsProductItemList" */ '@/views/backEnd/settings/productItems/SettingProductItemList.vue'),
            meta: {
                title: 'Product Item Settings',
                middleware: [
                    auth
                ],
            },
        },

        // Contact settings
        {
            path: '/settings/contact',
            name: 'settingsContactList',
            component: () => import(/* webpackChunkName: "settingsContactList" */ '@/views/backEnd/settings/contact/SettingContactList.vue'),
            meta: {
                title: 'Contact Settings',
                middleware: [
                    auth
                ],
            },
        },
        // Technician Commission Payment Settings
        {
            path: '/settings/technician-commission-payment',
            name: 'TechnicianCommissionPayment',
            component: () => import(/* webpackChunkName: "TechnicianCommissionPayment" */ '@/views/backEnd/settings/technicianCommissionPaymentSetting/TechnicianCommissionPaymentSetting.vue'),
            meta: {
                title: 'Technician commission payment Settings',
                middleware: [
                    auth
                ],
            },
        },
        // Payment Settings
        {
            path: '/settings/payment',
            name: 'PaymentSetting',
            component: () => import('@/views/backEnd/settings/payment/SettingPaymentList.vue'),
            meta: {
                title: 'Payment Settings',
                middleware: [
                    auth
                ],
            },
        },
        // Google map Settings
        {
            path: '/settings/google-map',
            name: 'GoogleMapSetting',
            component: () => import(/* webpackChunkName: "GoogleMapSetting" */ '@/views/backEnd/settings/googleMap/GoogleMapSetting.vue'),
            meta: {
                title: 'Google map Settings',
                middleware: [
                    auth
                ],
            },
        },
        // vendor Settings

        {
            path: '/settings/vendor',
            name: 'settingVendorList',
            component: () => import(/* webpackChunkName: "settingVendorList" */ '@/views/backEnd/settings/vendor/SettingVendorList.vue'),
            meta: {
                title: 'Vendor Settings',
                middleware: [
                    auth
                ],
            },
        },
        // *** settings end

        // franchisee Settings
        {
            path: '/settings/franchisee',
            name: 'franchiseeSettingList',
            component: () => import(/* webpackChunkName: "franchiseeSettingList" */ '@/views/backEnd/settings/franchisee/SettingsFranchiseeList.vue'),
            meta: {
                title: 'Franchisee Settings',
                middleware: [
                    auth
                ],
            },
        },
        {
            path: '/settings/franchisee-payment',
            name: 'franchiseePaymentSetting',
            component: () => import(/* webpackChunkName: "franchiseePaymentSetting" */ '@/views/backEnd/settings/franchisee/FranchiseePaymentSetting.vue'),
            meta: {
                title: 'Franchisee Payment Settings',
                middleware: [
                    auth
                ],
            },
        },
        // *** franchisee end

        // Blog Category
        {
            path: '/blog-categories',
            name: 'appBlogCategoryList',
            component: () => import(/* webpackChunkName: "BlogCategoryList" */ '@/views/backEnd/blogCategories/BlogCategoryList'),
            meta: {
                title: 'Blog Categories',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'blog-category-view-any',
                ],
            }
        },
        {
            path: '/blog-categories/create',
            name: 'appBlogCategoryCreate',
            component: () => import(/* webpackChunkName: "BlogCategoryCreate" */ '@/views/backEnd/blogCategories/BlogCategoryCreate'),
            meta: {
                title: 'Blog Categories',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'blog-category-create',
                ],
            }
        },

        // Blog

        {
            path: '/blogs/create',
            name: 'appBlogCreate',
            component: () => import(/* webpackChunkName: "BlogCreate" */ '@/views/backEnd/blogs/BlogCreate'),
            meta: {
                title: `Blogs`,
                middleware: [
                    auth,
                    hasPermissions
                ],

                permissionGates: [
                    'blog-create',
                ],
            },
        },

        {
            path: '/blogs',
            name: 'appBlogList',
            props: true,
            component: () => import(/* webpackChunkName: "VacancyList" */ '@/views/backEnd/blogs/BlogList'),
            meta: {
                title: `Blogs`,
                middleware: [
                    auth,
                    hasPermissions
                ],
                permissionGates: [
                    'blog-view-any',
                ],
            },
        },
        {
            path: '/blogs/:id',
            name: 'appBlogView',
            component: () => import(/* webpackChunkName: "BlogView" */ '@/views/backEnd/blogs/BlogView'),
            meta: {
                title: 'Blogs',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'blog-view',
                ],
            },
        },
        {
            path: '/blogs/:id/edit',
            name: 'appBlogEdit',
            component: () => import('@/views/backEnd/blogs/BlogEdit'),
            meta: {
                title: 'Blogs',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'blog-update',
                ],
            },
        },

        // **** franchisee payment route start  ****
        {
            path: '/franchisee-payments/create',
            name: 'appFranchiseePaymentCreate',
            component: () => import('@/views/backEnd/franchisee/CreateFranchiseePayments'),
            meta: {
                middleware: [
                    auth
                ],
                permissionGates: []
            },
        },

        {
            path: '/franchisee-payments',
            name: 'appFranchiseePaymentList',
            component: () => import('@/views/backEnd/franchisee/ListFranchiseePayments'),
            meta: {
                middleware: [
                    auth
                ],
                permissionGates: []
            },
        },

        {
            path: '/franchisee-payments/:id/view',
            name: 'appFranchiseePaymentView',
            component: () => import('@/views/backEnd/franchisee/ViewFranchiseePayment.vue'),
            meta: {
                middleware: [
                    auth
                ],
                permissionGates: []
            },
        },
        //  **** franchisee payment route end   ****

        //  **** event calendar route start   ****
        {
            path: '/event-calendars/create',
            name: 'appEventCalendarCreate',
            component: () => import('@/views/backEnd/eventCalendars/EventCalendarCreate'),
            meta: {
                middleware: [
                    auth
                ],
                permissionGates: []
            },
        },
        {
            path: '/event-calendars',
            name: 'appEventCalendarList',
            component: () => import(/* webpackChunkName: "EventCalendarList" */ '@/views/backEnd/eventCalendars/EventCalendarList'),
            meta: {
                title: `EventCalendars`,
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'event-calendar-view-any',
                ],
            },
        },

        {
            path: '/admin-tasks',
            name: 'appAdminTaskList',
            component: () => import(/* webpackChunkName: "AdminTask" */ '@/views/backEnd/adminTasks/AdminTask'),
            meta: {
                title: `Admin Tasks`,

            },
        },

        //  **** event calendar route end ****

        // **** customer feedbacks route start  ****

        {
            path: '/customer-feedbacks',
            name: 'appCustomerFeedbackList',
            component: () => import('@/views/backEnd/customerFeedbacks/CustomerFeedbackList'),
            meta: {
                permissionGates: [
                    'customer-feedback-view-any'
                ]
            },
        },
        // **** customer feedbacks route end   ****




        // **** scam-alert route start  ****

        {
            path: '/scams/create',
            name: 'appScamAlertCreate',
            component: () => import('@/views/backEnd/scams/ScamAlertCreate'),
            meta: {
                permissionGates: [
                    ''
                ]
            },
        },
        {
            path: '/scams',
            name: 'appScamAlertList',
            props: true,
            component: () => import('@/views/backEnd/scams/ScamAlertList'),
            meta: {
                title: `Scams`,
               
                permissionGates: [
                    '',
                ],
            },
        },
        {
            path: '/scams/:id/edit',
            name: 'appScamAlertEdit',
            component: () => import('@/views/backEnd/scams/ScamAlertEdit'),
            meta: {
                title: 'Scams',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'blog-update',
                ],
            },
        },
        {
            path: '/scams/:id',
            name: 'appScamAlertView',
            component: () => import('@/views/backEnd/scams/ScamAlertView'),
            meta: {
                title: 'Blogs',
                middleware: [
                    auth,
                    hasPermissions,
                ],
                permissionGates: [
                    'blog-view',
                ],
            },
        },

        
        // **** scam-alert route end    ****

        {
            path: '/settings/default/sms-short-template',
            name: 'settingDefaultSmsTemplate',
            props: true,
            component: () => import('@/views/backEnd/settings/default/smsShortTemplate/SettingDefaultSmsShortTemplateList.vue'),
            meta: {
                title: `SMS short template`,

                middleware: [
                    auth
                ],
                permissionGates: ['setting-default-view-any']
            },
        },


        {
            path: '/short-text-template',
            name: 'appShortTextTemplate',
            props: true,
            component: () => import('@/views/backEnd/shortTextTemplate/shortTextTemplateList.vue'),
            meta: {
                title: `Short text template`,

                middleware: [
                    auth
                ],
                permissionGates: ['short-text-view-any']
            },
        },

        {
            path: '/follow-ups',
            name: 'appFollowUpList',
            props: true,
            component: () => import('@/views/backEnd/followUps/FollowUpList.vue'),
            meta: {
                title: `Follow up list`,

                middleware: [
                    auth
                ],
                permissionGates: ['short-text-view-any']
            },
        },
        {
            path: '/consolidate/invoice/:uuid',
            name: 'appConsolidateInvoice',
            component: () => import('@/views/backEnd/consolidate/ConsolidateInvoice.vue'),
            meta: {
                middleware: [
                    auth
                ],
            },
        },
        {
            path: '/referrals/create',
            name: 'appReferralCreate',
            component: () => import('@/views/backEnd/users/referrals/ReferralCreate'),
            meta: {
                tile: 'Referrals',
                middleware: [
                    auth,
                    hasPermissions
                ],
                permissionGates: [
                    'referral-create',
                ]
            },
        },
        {
            path: '/referrals',
            name: 'appReferralList',
            component: () => import('@/views/backEnd/users/referrals/ReferralList'),
            meta: {
                tile: 'Referrals',
                middleware: [
                    auth,
                    hasPermissions
                ],
                permissionGates: [
                    'referral-view-any',
                ]
            },
        },
        {
            path: '/referrals/:id',
            name: 'appReferralView',
            component: () => import('@/views/backEnd/users/referrals/ReferralView'),
            meta: {
                tile: 'Referrals',
                middleware: [
                    auth,
                    hasPermissions
                ],
                permissionGates: [
                    'referral-view',
                ]
            },
        },
        {
            path: '/inquiry-responses',
            name: 'appInquiryResponsesList',
            props: true,
            component: () => import('@/views/backEnd/inquiryResponses/InquiryResponsesList.vue'),
            meta: {
                title: `Inquiry Responses list`,

            }
        },
        {
            path: '/technician-locations',
            name: 'appTechnicianLocation',
            component: () => import('@/views/backEnd/googleMap/TechnicianOnMap.vue'),
            meta: {
                title: `Technician Location`,
            },
        },
        // ****     incomes route starts ****
        /*{
            path: '/account/finance/payment/create',
            name: 'appAccountFinancePaymentsCreate',
            component: () => import('@/views/backEnd/AccountAndFinance/Finance/IncomesReportCreate.vue'),
            meta: {},
        },*/
        // ****     payment route ends ****

        // ****     payment route starts ****
        {
            path: '/account/finance/income/create',
            name: 'appAccountFinanceIncomeCreate',
            component: () => import('@/views/backEnd/AccountAndFinance/Finance/PaymentReportCreate.vue'),
            meta: {
                middleware: [
                    auth
                ],
                permissionGates: [
                    'payment-view-any'
                ]
            },
        },


    ]),
];

export default routes;