<script>
import AppLayout from "@/layouts/backEnd/AppLayout.vue";
import Loader from "@/components/backEnd/mixins/Loader";
import Map from "@/components/backEnd/Map.vue"
import {mapActions} from "vuex";

export default {
  name: "TechnicianOnMap",
  components: {
    AppLayout,
    Map
  },
  mixins: [Loader],
  data() {
    return {
      focusMarkerId: null,
      technicianMarkers: [], // Store technician locations
      mapCenter: {lat: -33.8605523, lng: 151.1972205}, // Default center
    };
  },

  methods: {
    ...mapActions({
      getUsersLocation: 'appUsers/getUsersLocation',
    }),

    async getUserLocationList() {
      await this.getUsersLocation().then(async response => {
        response.data.forEach(element => {
          this.updateTechnicianMarkers(element);
        })
      });
    },
    initializeWebSocket() {
      // Subscribe to the WebSocket channel
      window.Echo.channel("users.location.updated")
          .subscribed(() => {
            console.log("Subscribed to users.location.updated channel");
          })
          .listen(".location-updated", (data) => {
            console.log("Location updated:", data);
            this.updateTechnicianMarkers(data);
          });
    },
    async updateTechnicianMarkers(data) {
      // Update or add marker for a specific technician
      const index = this.technicianMarkers.findIndex(
          (marker) => marker.user_id === data.user_id
      );
      if (index !== -1) {
        // Update existing technician location
        this.technicianMarkers[index] = data;
      } else {
        // Add new technician location
        this.technicianMarkers.push(data);
      }
    },
  },
  async mounted() {
    this.initializeWebSocket();
    await this.getUserLocationList();
  },
}
</script>

<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">All technician on map</h4>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center">
              <div class="col-10 d-flex">
                <div class="col-3">
                  <div class="card ">
                    <div class="card-body">
                      <div>
                        <div class="mb-1">
                          <h5 class="text-bold-400 text-capitalize" style="text-transform: capitalize;">
                            Technician List
                          </h5>
                        </div>
                        <div>
                          <div
                              v-for="user in technicianMarkers"
                              :key="user.id"
                              class="user-item"
                              @click="focusMarkerId = user.id"
                          >
                            <img :src="user.avatar" alt="User Avatar" class="user-avatar">
                            <span>{{ user.user.full_name }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-9">
                  <div class="card ">
                    <div class="card-body">
                      <div>
                        <div class="mb-1">
                          <h5 class="text-bold-400 text-capitalize" style="text-transform: capitalize;">
                            Technician on Map
                          </h5>
                        </div>
                        <div>
                          <Map
                              :markers="technicianMarkers"
                              :center="mapCenter"
                              :focus-marker-id="focusMarkerId"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>
<style scoped>
.user-list {
  max-height: 500px;
  overflow-y: scroll;
  width: 200px;
  margin-left: 10px;
}

.user-item {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-item:hover {
  background-color: #f0f0f0;
}
</style>