<template>
  <div ref="mapContainer" class="map-container"></div>
</template>

<script>
//import {MarkerClusterer} from "@googlemaps/markerclusterer";

export default {
  name: 'Map',
  props: {
    markers: Array, // Array of technician locations
    center: Object, // Center coordinates { lat, lng }
    focusMarkerId: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      map: null,
      google: window.google,
      markerObjects: {}, // Store marker instances
    };
  },
  mounted() {
    this.initializeMap();
  },
  watch: {
    markers: {
      handler() {
        this.updateMarkers();
      },
      deep: true,
    },
    focusMarkerId: {
      handler(newVal) {
        if (newVal) {
          this.focusMarkerOnMap(newVal);
        }
      },
      immediate: true,
    },
  },
  methods: {
    initializeMap() {
      // Initialize the map
      this.map = new this.google.maps.Map(this.$refs.mapContainer, {
        center: this.center || {lat: 33.8688, lng: 151.2093}, // Default center
        zoom: 13,
      });
      // Initialize InfoWindow
      this.infoWindow = new this.google.maps.InfoWindow(); // Ensure it's properly initialized

      // Load markers and clustering
      this.updateMarkers();
    },
    updateMarkers() {
      const newMarkerIds = this.markers.map((location) => location.id);

      // Remove markers that are no longer present
      Object.keys(this.markerObjects).forEach((id) => {
        if (!newMarkerIds.includes(Number(id))) {
          this.markerObjects[id].markerElement.remove(); // Remove custom HTML element
          delete this.markerObjects[id]; // Remove from markerObjects mapping
        }
      });

      // Add or update markers
      this.markers.forEach((location) => {
        if (this.markerObjects[location.id]) {
          // Update existing marker's position if it already exists
          this.markerObjects[location.id].latLng = new this.google.maps.LatLng(
              location.latitude,
              location.longitude
          );
          this.redrawMarker(location.id);
        } else {
          // Create a new custom marker if it doesn't exist
          const markerElement = document.createElement("div");
          markerElement.className = "custom-marker";

          // Create a container for the avatar and name
          const contentContainer = document.createElement("div");
          contentContainer.className = "marker-content";

          // Create and append the name element
          const nameElement = document.createElement("div");
          nameElement.className = "marker-name";
          nameElement.textContent = location.user.full_name;
          contentContainer.appendChild(nameElement);

          // Create and append the avatar image
          const avatarImg = document.createElement("img");
          avatarImg.src = location.avatar; // Use avatar URL from props
          avatarImg.alt = location.user.full_name;
          avatarImg.className = "marker-avatar";
          contentContainer.appendChild(avatarImg);

          // Append the content container to the marker element
          markerElement.appendChild(contentContainer);

          const marker = new this.google.maps.OverlayView();
          marker.onAdd = () => {
            const panes = marker.getPanes();
            panes.overlayMouseTarget.appendChild(markerElement);
          };

          marker.draw = () => {
            const projection = marker.getProjection();
            const pos = projection.fromLatLngToDivPixel(
                new this.google.maps.LatLng(location.latitude, location.longitude)
            );

            markerElement.style.position = "absolute";
            markerElement.style.left = `${pos.x - 25}px`; // Adjust for marker size
            markerElement.style.top = `${pos.y - 50}px`; // Adjust for marker size
          };

          marker.onRemove = () => {
            markerElement.remove();
          };

          marker.latLng = new this.google.maps.LatLng(
              location.latitude,
              location.longitude
          );
          marker.markerElement = markerElement;

          marker.setMap(this.map);

          // Store the marker instance
          this.markerObjects[location.id] = marker;
        }
      });
    },
    focusMarkerOnMap(markerId) {
      const marker = this.markerObjects[markerId];
      if (marker && marker.latLng) {
        // Smoothly pan the map to the marker's position
        this.map.panTo(marker.latLng);

        // Smooth zoom-in effect
        const targetZoom = 15; // Target zoom level
        const currentZoom = this.map.getZoom();
        const zoomStep = currentZoom < targetZoom ? 1 : -1;

        let zoomInterval = setInterval(() => {
          const current = this.map.getZoom();
          if ((zoomStep > 0 && current >= targetZoom) || (zoomStep < 0 && current <= targetZoom)) {
            clearInterval(zoomInterval);
          } else {
            this.map.setZoom(current + zoomStep);
          }
        }, 100); // Adjust interval time (in milliseconds) for smoother or quicker effect
      } else {
        console.error("Marker not found or does not have a valid position.");
      }
    },
    redrawMarker(markerId) {
      const marker = this.markerObjects[markerId];
      const projection = marker.getProjection();
      const pos = projection.fromLatLngToDivPixel(marker.latLng);

      const markerElement = marker.markerElement;
      markerElement.style.left = `${pos.x - 25}px`;
      markerElement.style.top = `${pos.y - 50}px`;
    },
    showAllMarkers() {
      if (!this.markers || this.markers.length === 0) {
        console.error("No markers available to display.");
        return;
      }

      // Create a bounds object
      const bounds = new this.google.maps.LatLngBounds();

      // Extend bounds to include each marker's position
      this.markers.forEach((marker) => {
        if (marker.latitude && marker.longitude) {
          const latLng = new this.google.maps.LatLng(marker.latitude, marker.longitude);
          bounds.extend(latLng);
        }
      });

      // Adjust the map to fit all markers
      this.map.fitBounds(bounds);
    },

  },

};
</script>

<style>
.map-container {
  width: 100%;
  height: 500px;
}

.custom-marker {
  position: absolute;
  width: 40px; /* Adjust size as needed */
  height: 60px; /* Adjust size as needed */
  text-align: center;
}

.marker-content {
  position: relative;
  width: 100%;
  height: 100%;
}

.marker-name {
  position: absolute;
  top: -15px; /* Position the name above the avatar */
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  font-weight: bold;
  color: #ff5733; /* Adjust text color as needed */
  white-space: nowrap; /* Prevent text from wrapping */
}

.marker-avatar {
  width: 40px; /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
  border-radius: 50%;
  border: 2px solid #ff5733; /* Optional: adds a border around the avatar */
}

</style>
