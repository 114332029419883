<script>
import ConfirmDialogue from "@/components/backEnd/modal/ConfirmDialogue.vue";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import {mapActions} from "vuex";
import GlobalMixin from "@/components/backEnd/mixins/GlobalMixin";
import {QuillEditor} from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  name: "InquiryResponseModal",
  mixins: [ShowToastMessage, Loader, GlobalMixin],
  props: ['appointment', 'appointmentParams'],
  components: {ConfirmDialogue, QuillEditor},
  data() {
    return {
      paymentCollection: {},
      paymentCollectionDetails: [],
      stage1: 'I wanted to bring to your attention that there is an outstanding balance on your account.' +
          'we kindly request that you settle this payment at your earliest convenience.',
      message: '',
      isLoading: false,
      selectedReference: '',
      searchDebounce: null,
      currentAppointmentId: '',
      inquiryResponses: []
    }
  },
  computed: {
    referenceOptions() {
      return this.$store.getters['appAppointments/appointments'].map((appointment) => ({
        value: appointment.id,
        appointment: appointment,
        name: `${appointment.reference} ${appointment.customer.user?.full_name} <${appointment.customer.user?.email ?? ''}>`
      }));
    },
  },
  watch: {
    appointment: {
      handler(currentVale) {
        console.log(currentVale)
        /* this.paymentCollection = currentVale?.appointmentPaymentCollection;
         this.paymentCollectionDetails = currentVale?.appointmentPaymentCollection?.details != null ? JSON.parse(currentVale.appointmentPaymentCollection.details) : [];
         if (this.paymentCollectionDetails.length > 0) {
           Object.entries(this.stageTemplate).forEach(([stageKey, value]) => {
             const stageIndex = this.paymentCollectionDetails.findIndex((item) => item.type === stageKey);
             let message = (stageIndex === -1) ? value.template : this.paymentCollectionDetails[stageIndex]['message'];
             let isSend = (stageIndex !== -1);
             this.details[stageKey]['message'] = message;
             this.details[stageKey]['isSend'] = isSend;
             this.$refs[`${stageKey}`].setHTML(message);
           });
         } else {
           Object.entries(this.stageTemplate).forEach(([stageKey, value]) => {
             this.details[stageKey]['message'] = value?.template;
             this.$refs[`${stageKey}`].setHTML(value?.template);
           });
         }*/
      },
    },
    async selectedReference(value) {
      const appointment = value.appointment;
      if (appointment && typeof appointment === 'object' && !Array.isArray(appointment)) {
        this.currentAppointmentId = appointment.id;
        await this.getInquiriesOnly();
      }

    },
  },
  methods: {
    ...mapActions({
      postAppointmentPaymentCollectionNotifyEmail: 'appAppointments/postAppointmentPaymentCollectionRequestNotifyEmail',
      putAppointmentPaymentCollection: 'appAppointmentPaymentCollections/putAppointmentPaymentCollection',
      getAppointments: 'appAppointments/getAppointments',
      getFollowUps: 'appFollowUps/getFollowUpsWithoutStore',
      postFollowUpOnList: 'appFollowUps/postFollowUpOnList',
      postInquiryResponseCreationNotifyEmail: 'appFollowUps/postInquiryResponseCreationNotifyEmail',
    }),
    async getInquiriesOnly() {
      const params = {type: 1, with_relation: ['createdBy'], reference_id: this.currentAppointmentId}
      await this.getFollowUps(params).then(async (response) => {
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
        this.inquiryResponses = response.data
      })
    },

    async findReference(query) {
      if (query.length > 2) {
        clearTimeout(this.searchDebounce);
        this.isLoading = true;
        this.searchDebounce = setTimeout(async () => {
          const params = {
            reference: query,
            with_relation: ['service', 'customer.user', 'customer.address']
          }
          await this.getAppointments(params)
              .then((response) => {
                this.isLoading = !!(response && response.message && response.status !== 200);
              });
        }, 900);
      }
    },

    async sendEmailHandler() {
      if (this.message === '') {
        this.showToastMessage({type: 'error', message: 'Please provide message'});
        return;
      }
      if (this.selectedReference.value == null) {
        this.showToastMessage({type: 'warning', message: 'Please select reference'});
        return;
      }

      const data = {
        reference_id: this.selectedReference.value,
        body: this.message,
        calling_date: this.getDateTime(),
        status: 1, // active
        type: 1, //inquiry
        created_by: this.$store.getters["appAuthLogin/authUser"].id,

      };
      this.loader(true);
      await this.postFollowUpOnList(data).then(async (response) => {

        if (response.status === 200 || response.status === 201) {
          const emailResponse = await this.postInquiryResponseCreationNotifyEmail({
            followUp: response.data.id,
            params: {
              notify_customer: 1
            }
          });
          this.loader(false);

          if (emailResponse.status === 204) {
            document.querySelector('[data-target="#inquiryResponseModal"]').click();
            this.showToastMessage({type: 'success', message: ' Email sent successfully !'});
          }
        } else {
          this.showToastMessage(response);
        }
      });
    }
  }
}
</script>

<template>
  <div class="modal fade " id="inquiryResponseModal" tabindex="-1" role="dialog"
       aria-labelledby="inquiryResponseModal" aria-hidden="true">
    <div class="modal-dialog  modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">
            <div></div>
          </h1>
          <div class="modal-title white">
          </div>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <fieldset class="col-12">
              <div class="col-12 col-sm-12">
                <div class="form-group position-relative">
                  <label>Search Reference</label>
                  <VueMultiselect v-model="selectedReference" placeholder="Type to search"
                                  label="name" track-by="name" id="ajax" open-direction="bottom"
                                  :options="referenceOptions" :searchable="true" :loading="isLoading"
                                  :options-limit="300" :limit="3" :show-no-results="true"
                                  :hide-selected="true" @search-change="findReference">
                    <!--                    <template #singleLabel="props">
                                          <span class="option__image" > </span>
                                          <span class="option__desc"><span
                                            class="option__title">{{ props.option.title }}</span></span>
                                        </template>
                                        <template #option="props">
                                          <img class="option__image" :src="props.option.img" alt="No Man’s Sky"/>
                                          <div class="option__desc"><span class="option__title">{{ props.option.title }}</span><span
                                              class="option__small">{{ props.option.desc }}</span></div>
                                        </template>-->
                  </VueMultiselect>

                </div>
              </div>
              <div></div>
              <div class="col-12 col-sm-12">
                <div class="table-responsive border py-1 my-1">
                  <table id="users-list-datatable" class="table table-sm">
                    <thead>
                    <tr>
                      <th class="position-relative" style="width: 6%">SL</th>
                      <th class="position-relative" style="width: 64%">Message</th>
                      <th class="position-relative" style="width: 30%">date & created by</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row,index) in inquiryResponses" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td v-html="row.body"></td>
                      <td>
                        <span>{{ row.createdBy.full_name }}</span><br>
                        <span>{{ row.calling_date }}</span>
                      </td>

                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-12 col-sm-12">

                <div class="form-group">
                  <label for="stageTextarea1">Content</label>
                  <QuillEditor ref="stage1" v-model:content="message" contentType="html"
                               toolbar="essential"
                               theme="snow"
                               placeholder="Enter Description" id="stageTextarea1"/>
                </div>
                <div class="d-grid gap-2 col-3 mx-auto mb-2">
                  <button class="btn btn-primary" type="button" @click="sendEmailHandler">Send</button>
                </div>
              </div>
            </fieldset>
          </div>

        </div>
        <div class="modal-footer border-0 pt-0">

        </div>
      </div>
    </div>
    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  </div>
</template>

<style scoped>

</style>