<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="appointment-setting-wrapper">
          <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0">
            <h4 class="text-sm-center">Coupon Setting List</h4>
          </div>

          <div class="accordion collapse-icon accordion-icon-rotate" id="couponAccordion">


            <!-- coupon status -->
            <div class="card">
              <div class="card-header" id="headingCouponOnlineAppointmentStatus">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseOnlineAppointmentStatus" aria-expanded="false"
                        aria-controls="collapseOnlineAppointmentStatus">
                  <span class="text-capitalize appointment-setting-title">Coupon-
                    <span class="text-success">Online Booking Discount</span> |
                    <span class="text-primary">Parking Discount</span>
                  </span>
                </button>

              </div>
              <div id="collapseOnlineAppointmentStatus" class="collapse"
                   aria-labelledby="headingCouponOnlineAppointmentStatus" data-parent="#couponAccordion">
                <div class="card-body">
                  <div class="d-flex flex-column flex-lg-row">
                    <div class="w-100 mr-1 mb-2 mb-lg-0 border rounded">
                      <h6 class="text-center text-bold-800 bg-dark text-white text-uppercase p-2">Online booking
                        discount over coupon discount</h6>
                      <SettingCouponOverOnlineAppointmentStatus
                          :coupon-discount-status="onlineAppointmentDiscountOverCouponDiscount"/>
                    </div>
                    <div class="w-100 mr-1 mb-2 mb-lg-0 border rounded">
                      <h6 class="text-center text-bold-800 bg-dark text-white text-uppercase p-2">Parking discount over
                        coupon discount</h6>
                      <SettingCouponOverParkingStatus :coupon-discount-status="parkingDiscountOverCouponDiscount"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- coupon weekend days -->
            <div class="card">
              <div class="card-header" id="headingCouponWeekendDays">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseCouponWeekendDays" aria-expanded="false"
                        aria-controls="collapseCouponWeekendDays">
                  <span class="text-capitalize appointment-setting-title">Coupon-
                    <span class="text-primary">Weekend Days</span>
                  </span>
                </button>

              </div>
              <div id="collapseCouponWeekendDays" class="collapse"
                   aria-labelledby="headingCouponWeekendDays" data-parent="#couponAccordion">
                <div class="card-body">
                  <div class="d-flex flex-column flex-lg-row">
                    <div class="w-100 mr-1 mb-2 mb-lg-0 border rounded">
                      <h6 class="text-center text-bold-800 bg-dark text-white text-uppercase p-2">Coupon weekend days</h6>
                      <SettingCouponWeekday :coupon-weekend-days="couponWeekendDays"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue';
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import {
  mapActions,
  mapGetters
} from 'vuex';
import SettingCouponOverOnlineAppointmentStatus
  from "@/views/backEnd/settings/coupon/include/SettingCouponDiscountStatus.vue";
import SettingCouponOverParkingStatus
  from "@/views/backEnd/settings/coupon/include/SettingCouponDiscountStatus.vue";
import SettingCouponWeekday from "@/views/backEnd/settings/coupon/include/SettingCouponWeekday.vue";

export default {
  name: "SettingCouponList",
  components: {
    AppLayout,
    SettingCouponOverOnlineAppointmentStatus,
    SettingCouponOverParkingStatus,
    SettingCouponWeekday,

  },
  mixins: [ShowToastMessage, Loader],
  data() {
    return {
      getSettingsParams: {
        type: ["coupon", "discount"],

        key: [
          "discount_parking_discount_over_coupon_discount",
          "discount_online_appointment_discount_over_coupon_discount",
          "coupon_weekend_days",
        ],
      },
      currentState: {},
      frontEndErrors: [],
    }
  },

  computed: {
    ...mapGetters({
      parkingDiscountOverCouponDiscount: "appSettings/settingDiscountParkingDiscountOverCouponDiscount",
      onlineAppointmentDiscountOverCouponDiscount: "appSettings/settingDiscountOnlineAppointmentDiscountOverCouponDiscount",
      couponWeekendDays: "appSettings/settingCouponWeekendDays",
    }),

  },

  methods: {
    ...mapActions({
      getSettings: "appSettings/getSettings",
    }),
    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.loader(false);
  },

}
</script>

<style>
.appointment-setting-wrapper .accordion .card {
  overflow: visible !important;
}

.appointment-setting-title {
  font-size: 16px;
  color: rgb(44, 44, 44);
}
</style>
