<template>
  <div class="w-100">
    <div class="d-flex justify-content-around w-100 align-items-end mb-1">
      <div class="py-1 mb-0 mb-md-0">
        <div class="form-group">
          <div class="controls">
            <div class="custom-control custom-switch custom-switch-success custom-control-inline mb-1 d-flex align-items-center">
              <div>
                <input v-model="checkBoxStatus" type="checkbox" class="custom-control-input"
                       :id="currentSetting.key">
                <label class="custom-control-label mr-1" :for="currentSetting.key">
                  <span class="switch-icon-left"><i class="bx bx-check"></i></span>
                  <span class="switch-icon-right"><i class="bx bx-x"></i></span>
                </label>
              </div>
              <span class="font-medium-1 text-bold-600">{{ checkBoxStatus === true ? "Active" : "Inactive" }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="py-2">
        <button :disabled="isUpdateButtonEnable" @click="updateSettingsHandler" class="btn btn-primary px-lg-2"
                type="submit">Update
        </button>
      </div>

    </div>
  </div>


</template>

<script>

import {mapActions} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";


export default {
  name: "SettingCouponDiscountStatus",
  mixins: [ShowToastMessage, Loader],
  props: ['couponDiscountStatus'],
  data() {
    return {
      currentSetting: this.couponDiscountStatus,
      checkBoxStatus: 0,
    };
  },
  computed: {
    isUpdateButtonEnable() {
      return this.currentSetting.value === '' || this.currentSetting.value === null;
    }
  },
  watch: {
    'currentSetting.value'(newValue) {
      this.checkBoxStatus = newValue == 1;
    },
    checkBoxStatus(newValue) {
      this.currentSetting.value = (newValue === true ? 1 : 0);
    },

  },
  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
    }),

    async updateSettingsHandler() {
      await this.updateSettings();
    },

    async updateSettings() {
      let dataObj = {
        id: this.currentSetting.id,
        data: {
          type: this.currentSetting.type,
          key: this.currentSetting.key,
          value: this.currentSetting.value,
        }
      };
      await this.loader(true);
      await this.putSetting(dataObj).then(async (response) => {
        await this.loader(false);

        if (response.status === 200) {
          const toastObj = {
            message: 'Status updated.',
            type: 'success'
          };

          this.showToastMessage(toastObj);
        }
        this.showToastMessage(response);
      });
    }
  },
}
</script>

<style scoped>

</style>